"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Photos = __importStar(require("./PhotosData"));
const PhotosPage = () => {
    const photosData = Object.values(Photos);
    const [visiblePhotos, setVisiblePhotos] = (0, react_1.useState)(0);
    const [increment, setIncrement] = (0, react_1.useState)(12);
    (0, react_1.useEffect)(() => {
        const updatePhotoMetrics = () => {
            const width = window.innerWidth;
            let cols = 4;
            if (width < 640) {
                cols = 1;
            }
            else if (width < 768) {
                cols = 2;
            }
            else if (width < 1024) {
                cols = 3;
            }
            const newIncrement = cols * 3;
            setIncrement(newIncrement);
            setVisiblePhotos(newIncrement);
        };
        window.addEventListener('resize', updatePhotoMetrics);
        updatePhotoMetrics();
        return () => window.removeEventListener('resize', updatePhotoMetrics);
    }, []);
    const loadMorePhotos = () => {
        setVisiblePhotos(prev => prev + increment);
    };
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "py-6 bg-[#9d9d9d]" }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "mx-auto max-w-6xl p-4 rounded-lg" }, { children: [(0, jsx_runtime_1.jsx)("h1", Object.assign({ className: "font-moglanDemo text-7xl font-bold text-center text-white mb-8" }, { children: "PHOTOS" })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8' }, { children: photosData.slice(0, visiblePhotos).map((photo, index) => ((0, jsx_runtime_1.jsx)("img", { src: photo, alt: `Photo ${index + 1}`, className: 'w-full h-full object-cover' }, index))) })), visiblePhotos < photosData.length && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ className: "font-moglanDemo mt-4 px-4 py-2 bg-black text-white rounded hover:bg-gray-400", onClick: loadMorePhotos }, { children: "Load More" })) })))] })) })));
};
exports.default = PhotosPage;
