"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
function ButtonHolder(props) {
    const buttonLinks = {
        "Home": "#home",
        "Music": "#music",
        "Videos": "#videos",
        "Photos": "#photos",
        "Tours": "#tours",
        "Subscribe": "#subscribe"
    };
    return ((0, jsx_runtime_1.jsx)("div", { children: props.buttons.map((buttonLabel, index) => ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: buttonLinks[buttonLabel] || "#home" }, { children: (0, jsx_runtime_1.jsx)("button", Object.assign({ className: "font-goldenBird navbar-button" }, { children: buttonLabel })) }), index))) }));
}
exports.default = ButtonHolder;
